.progressBar,
._progressBar {
  animation-name: progressBar;
  animation-timing-function: linear;
  animation-fill-mode: forwards;
  width: 0;
}

@keyframes progressBar {
  from {width: 0;}
  to {width: 100%;}
}
