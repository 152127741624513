.publicTransport {
  animation-name: PpublicTransport, slideOutDown;
  animation-duration: 1s;
}

@keyframes publicTransport {
  0% {
  }
  90% {
    display: none;
  }
}
