@tailwind base;
@tailwind components;
@tailwind utilities;
@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

body { font-family: "Open Sans"; }

@layer base {
  .infobar-list > .current {
    @apply text-xl font-semibold relative;
  }

  .infobar-list > .current::before {
    background-image: url('data:image/svg+xml;utf8,<svg width="18" height="22" viewBox="0 0 18 22" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M18 11L-9.78799e-07 21.3923L-7.02746e-08 0.607695L18 11Z" fill="black"/></svg>');
    content: ' ';
    transform: translate(-50%, -50%);
    @apply h-6 w-6 bg-contain bg-center top-1/2 -left-3.5 absolute bg-no-repeat;
  }

  .shadow-opasti {
    --tw-shadow: 0 10px 10px 0px rgba(0,0,0,0.3);
    box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
  }
  .shadow-newinfo {
    --tw-shadow: 0 0px 4px 0px rgba(0,0,0,0.2);
    box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
  }
  .shadow-infobar {
    --tw-shadow: 0 -3px 7px 0px rgba(0,0,0,0.3);
    box-shadow: var(--tw-ring-offset-shadow,0 0 transparent),var(--tw-ring-shadow,0 0 transparent),var(--tw-shadow);
  }

  h1 {
    @apply text-3xl;
  }

  h2 {
    @apply text-2xl;
  }

  ol {
    @apply list-decimal list-inside my-2;

  }

  ul {
    @apply list-disc list-inside my-2;
  }
}

@layer components {
  .notice-content, .notice-content p {
    @apply text-xl;
  }
  
  .widget {
    @apply text-3xl flex items-center gap-2 px-6 rounded-full border h-16;
  }

  .flex-even {
    flex-basis: 50%;
    flex-grow: 1;
    flex-shrink: 1;
  }
}
